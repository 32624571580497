import { Redirect, Route } from 'react-router-dom';
import authService from '../../services/auth.service';

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (authService.isAuthenticated() ? <Redirect to="/" /> : <Component {...rest} {...props} />)} />
);

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  if (authService.isAuthenticated()) {
    return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />;
  } else {
    return <Route {...rest} render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />;
  }
};
