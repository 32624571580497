import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import vendorService from '../../services/vendor.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const listVendors = createAsyncThunk('vendors/list_vendors', async (_, { rejectWithValue }) => {
  try {
    return await vendorService.listVendors();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendor = createAsyncThunk('vendors/get_vendor', async (id, { rejectWithValue }) => {
  try {
    return await vendorService.getVendor(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateVendor = createAsyncThunk('vendors/update_vendor', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.updateVendor(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listVendorProducts = createAsyncThunk('vendors/list_vendor_products', async (id, { rejectWithValue }) => {
  try {
    return await vendorService.listVendorProducts(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorProduct = createAsyncThunk('vendors/get_vendor_product', async (id, { rejectWithValue }) => {
  try {
    return await vendorService.getVendorProduct(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateVendorProduct = createAsyncThunk('vendors/update_vendor_product', async (data, { rejectWithValue }) => {
  try {
    return await vendorService.updateVendorProduct(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'vendors',
  initialState,
  reducers: {},
  extraReducers: {
    /** List vendors reducer **/
    [listVendors.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listVendors.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listVendors.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get vendor reducer **/
    [getVendor.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendor.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendor.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update vendor reducer **/
    [updateVendor.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateVendor.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateVendor.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List vendor products reducer **/
    [listVendorProducts.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listVendorProducts.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listVendorProducts.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get vendor product reducer **/
    [getVendorProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendorProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendorProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update vendor product reducer **/
    [updateVendorProduct.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateVendorProduct.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateVendorProduct.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
