import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../../../../components/Table';
import moment from 'moment';
import { Calendar, Select, Radio } from 'antd';
import 'antd/dist/antd.css';
import { showToast } from '../../../../store/slices/components.slice';
import { listUserOrders } from '../../../../store/slices/order.slice';
import { classNames } from '../../../../utils';
import OrderInfo from './OrderInfo';
import { statusFormatter, monthToText } from '../../../../utils';

const Orders = ({ id, startDate, endDate }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [calendarType, setCalendarType] = useState('month');
  const { orderApi } = useSelector((state) => state);

  useEffect(() => {
    handleListUserOrders(id, startDate, endDate);
  }, []);

  const handleListUserOrders = (userUid, startDate, endDate) => {
    dispatch(listUserOrders({ userUid, startDate, endDate }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data?.orders);
        setCalendarData(data?.orders);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleFilterOrderByDate = ({ userUid, startDate, endDate }) => {
    dispatch(listUserOrders({ userUid, startDate, endDate }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data?.orders);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const handleFilterOrderByMonth = ({ userUid, startDate, endDate }) => {
    dispatch(listUserOrders({ userUid, startDate, endDate }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data?.orders);
        setCalendarData(data?.orders);
      })
      .catch(({ error, message }) => {
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const tableHeaders = [
    {
      title: 'Order ID',
      objPropKey: 'uid',
      render: (uid) => (
        <Link to={`/user/${id}/order/${uid}`} className="text-sm font-medium text-primary-600 hover:text-primary-700">
          {uid}
        </Link>
      ),
    },
    {
      title: 'Status',
      objPropKey: 'status',
      render: (status) => (
        <p className="text-sm text-500" style={{ color: statusFormatter(status).color }}>
          {statusFormatter(status)?.text}
        </p>
      ),
    },
    {
      title: 'Amount (RM)',
      objPropKey: 'totalPrice',
      render: (totalPrice) => <span>{totalPrice.toFixed(2)}</span>,
    },
    {
      title: 'City',
      objPropKey: 'city',
    },
    {
      title: 'Delivery date',
      objPropKey: 'deliverDate',
      render: (deliverDate, order) => <span>{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Delivery time',
      objPropKey: 'deliverTime',
      render: (deliverDate, order) => <span>{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('hh:mm A')}</span>,
    },
  ];

  const dateCellRender = (value) => {
    let arr = [];

    calendarData?.forEach((data) => {
      if (moment(value).format('YYYY-MM-DD') === data.deliverDate) {
        arr.push(data);
      }
    });

    return (
      <div className="flex items-center justify-center w-full">
        <span className={classNames(arr.length > 0 ? 'text-primary-500 font-medium' : 'text-gray-300', 'text-xs')}>
          {arr.length} {arr.length > 1 ? 'orders' : 'order'}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="mt-4 border rounded-md overflow-hidden">
        <Calendar
          fullscreen={false}
          dateCellRender={dateCellRender}
          onSelect={(data) => {
            calendarType === 'year'
              ? handleFilterOrderByMonth({
                  userUid: id,
                  startDate: moment(data).startOf('month').format('YYYY-MM-DD'),
                  endDate: moment(data).endOf('month').format('YYYY-MM-DD'),
                })
              : handleFilterOrderByDate({
                  userUid: id,
                  startDate: moment(data).format('YYYY-MM-DD'),
                  endDate: moment(data).format('YYYY-MM-DD'),
                });
          }}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.monthsShort(current));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                  {months[index]}
                </Select.Option>
              );
            }

            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              );
            }
            return (
              <div className="flex flex-wrap md:flex-nowrap items-center justify-between py-4 md:py-6 px-6">
                <button type="button" onClick={() => setTableData(calendarData)} className="flex items-center text-primary-600 hover:text-primary-500 focus:outline-none">
                  Reset filter
                </button>
                <div>
                  <span className="text-primary-500 hover:text-primary-400 ">
                    {monthToText(month)} {year}
                  </span>
                </div>
                <div className="flex space-x-4">
                  <div className="flex">
                    <Radio.Group
                      size="small"
                      onChange={(e) => {
                        onTypeChange(e.target.value);
                        setCalendarType(e.target.value);
                      }}
                      value={type}
                    >
                      <Radio.Button value="month">Month</Radio.Button>
                      <Radio.Button value="year">Year</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                      value={String(year)}
                    >
                      {options}
                    </Select>
                  </div>
                  {/* <div>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={(selectedMonth) => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        handleFilterOrderByMonth({
                          userUid: id,
                          startDate: moment(newValue).startOf('month').format('YYYY-MM-DD'),
                          endDate: moment(newValue).endOf('month').format('YYYY-MM-DD'),
                        });
                        onChange(newValue);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </div> */}
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="mt-6 flex flex-col">
        <div className="block md:hidden divide-y space-y-6">
          {tableData?.map((data, index) => (
            <div key={index} className="p-4 bg-white border rounded-lg shadow-sm">
              <p className="text-sm text-gray-500">
                Delivery on: {moment(`${data?.deliverDate} ${data?.deliverTime}`).format('DD MMM YYYY')} {moment(`${data?.deliverDate} ${data?.deliverTime}`).format('hh:mm A')}
              </p>
              <p className="text-sm text-500" style={{ color: statusFormatter(data?.status).color }}>
                {statusFormatter(data?.status)?.text}
              </p>
              <Link to={`/order/${data?.uid}`} className="mt-1 flex items-center font-medium">
                <h3 className="mr-4 text-amber-600">{data?.uid}</h3>
                <span className="ml-auto text-right flex-shrink-0 text-lg">RM {data?.totalPrice?.toFixed(2)}</span>
              </Link>
              <p className="mt-1 text-sm text-gray-500">{data?.city}</p>
            </div>
          ))}
        </div>
        <div className="hidden md:block">
          <Table
            dataInput={tableData}
            headers={tableHeaders}
            pagination={{ limit: 10, pageNeighbours: 1 }}
            loading={orderApi.loading}
            dateFilter={(data) => {
              let payload = data;
              payload.userUid = id;
              handleFilterOrderByDate(payload);
            }}
          >
            <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
              <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
                <path
                  fillRule="evenodd"
                  d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mt-2 font-medium">No result found</h3>
            </div>
          </Table>
        </div>
      </div>
    </>
  );
};

Orders.Info = OrderInfo;

export default Orders;
