import api from './index';

class ProfileService {
  getProfile() {
    return api.get('/profile').then((response) => {
      return response.data;
    });
  }

  updateProfile(data) {
    return api.put('/profile', data).then((response) => {
      return response.data;
    });
  }
}

export default new ProfileService();
