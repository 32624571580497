import api from './index';

class OrderService {
  listVendorOrders({ vendorUid, startDate, endDate }) {
    let path = `/order?vendorUid=${vendorUid}`;

    if (vendorUid && startDate && endDate) {
      path = `/order?vendorUid=${vendorUid}&startDate=${startDate}&endDate=${endDate}`;
    }

    return api.get(path).then((response) => {
      return response.data;
    });
  }

  listUserOrders({ userUid, startDate, endDate }) {
    let path = `/order?userUid=${userUid}`;

    if (userUid && startDate && endDate) {
      path = `/order?userUid=${userUid}&startDate=${startDate}&endDate=${endDate}`;
    }

    return api.get(path).then((response) => {
      return response.data;
    });
  }

  getVendorOrder(orderId) {
    return api.get(`/order/${orderId}`).then((response) => {
      return response.data;
    });
  }

  getUserOrder(orderId) {
    return api.get(`/order/${orderId}`).then((response) => {
      return response.data;
    });
  }
}

export default new OrderService();
