import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Image from '../../../components/Image';

import { showToast } from '../../../store/slices/components.slice';
import { listUser } from '../../../store/slices/user.slice';

import UserDetails from './Details';
import UserProfilePhotos from './Photos/Profile';
import OrderInfo from './Orders/OrderInfo';

const User = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { userApi } = useSelector((state) => state);

  useEffect(() => {
    handleListUser();
  }, []);

  const handleListUser = () => {
    dispatch(listUser())
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data?.users);
      })
      .catch((result) => {
        const { error, message } = result;
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const tableHeaders = [
    {
      title: 'User ID List',
      objPropKey: 'uid',
      render: (uid, { name, profilePic }) => (
        <div class="flex items-center">
          {profilePic ? (
            <Image src={profilePic?.path?.s} alt={`${name}-userPhoto`} className="h-[48px] w-[48px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10" />
          ) : (
            <div className="flex items-center h-[48px] w-[48px] rounded-full overflow-hidden bg-primary-100 focus:outline-none">
              <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
          )}
          <Link className="ml-4" to={`/user/${uid}`}>
            <div class="text-sm font-medium text-primary-600 hover:text-primary-700">{name || uid}</div>
          </Link>
        </div>
      ),
    },
    {
      title: 'Email',
      objPropKey: 'email',
    },
    {
      title: 'Phone Number',
      objPropKey: 'phoneNumber',
    },
  ];

  return (
    <>
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-bold text-gray-900">Users</h2>
      </div>
      <div className="mt-6 flex flex-col">
        <Table dataInput={tableData} headers={tableHeaders} pagination={{ limit: 10, pageNeighbours: 1 }} loading={userApi.loading} enableFilter={true}>
          <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
            <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
              <path
                fill-rule="evenodd"
                d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h3 className="mt-2 font-medium">No result found</h3>
          </div>
        </Table>
      </div>
    </>
  );
};

User.Details = UserDetails;
User.ProfilePhotos = UserProfilePhotos;
User.OrderInfo = OrderInfo;

export default User;
