class AuthService {
  isAuthenticated() {
    const token = JSON.parse(localStorage.getItem('token'));
    const access_token = token?.access_token;

    if (access_token) {
      return true;
    } else {
      return false;
    }
  }

  logout(history) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.push('/');
  }
}

export default new AuthService();
