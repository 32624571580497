import api from './index';

import TokenService from './token.service';

class AdminService {
  createImpersonateSession({ user }) {
    const auth = TokenService.getToken();
    return api.post(`/service/create-impersonate-session`, { user, auth }).then((response) => {
      return response.data;
    });
  }
}

export default new AdminService();
