import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/slices/components.slice';

const Toast = ({ show = false, type, subject, message }) => {
  const dispatch = useDispatch();
  const { components } = useSelector((state) => state);
  const toast = components.toast;

  useEffect(() => {
    if (toast.show && !['warning', 'error'].includes(type)) {
      const timer = setTimeout(() => {
        dispatch(showToast({ ...toast, show: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, dispatch, toast]);

  switch (type) {
    case 'success':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed z-50 origin-top-right top-0 right-0 sm:m-6 w-full sm:w-96 sm:rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <div className="text-sm p-4 flex items-start">
              <svg className="w-6 h-6 mr-2.5 flex-shrink-0 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <div className="mr-4">
                <span className="font-medium">Success</span>
                <p className="mt-1 text-gray-500 leading-relaxed">{message}</p>
              </div>
              <svg
                onClick={() => dispatch(showToast({ ...toast, show: false }))}
                className="w-5 h-5 text-gray-500 ml-auto cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </Transition>
      );
    case 'warning':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed z-50 origin-top-right top-0 right-0 sm:m-6 w-full sm:w-96 sm:rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <div className="text-sm p-4 flex items-start">
              <svg className="w-6 h-6 mr-2.5 flex-shrink-0 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                ></path>
              </svg>
              <div className="mr-4">
                <span className="font-medium">Warning</span>
                <p className="mt-1 text-gray-500 leading-relaxed">{message}</p>
              </div>
              <svg
                onClick={() => dispatch(showToast({ ...toast, show: false }))}
                className="w-5 h-5 text-gray-500 ml-auto cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </Transition>
      );
    case 'error':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed z-50 origin-top-right top-0 right-0 sm:m-6 w-full sm:w-96 sm:rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <div className="text-sm p-4 flex items-start">
              <svg className="w-6 h-6 mr-2.5 flex-shrink-0 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <div className="mr-4">
                <span className="font-medium">Error</span>
                <p className="mt-1 text-gray-500 leading-relaxed">{message}</p>
              </div>
              <svg
                onClick={() => dispatch(showToast({ ...toast, show: false }))}
                className="w-5 h-5 text-gray-500 ml-auto cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </Transition>
      );
    case 'info':
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed z-50 origin-top-right top-0 right-0 sm:m-6 w-full sm:w-96 sm:rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <div className="text-sm p-4 flex items-start">
              <svg className="w-6 h-6 mr-2.5 flex-shrink-0 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <div className="mr-4">
                <span className="font-medium">Info</span>
                <p className="mt-1 text-gray-500 leading-relaxed">{message}</p>
              </div>
              <svg
                onClick={() => dispatch(showToast({ ...toast, show: false }))}
                className="w-5 h-5 text-gray-500 ml-auto cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </Transition>
      );
    default:
      return (
        <Transition
          as={Fragment}
          show={show}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed z-50 origin-top-right top-0 right-0 sm:m-6 w-full sm:w-96 sm:rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <div className="text-sm p-4 flex items-start">
              <div className="mr-4">
                <span className="font-medium">{subject}</span>
                <p className="mt-1 text-gray-500 leading-relaxed">{message}</p>
              </div>
              <svg
                onClick={() => dispatch(showToast({ ...toast, show: false }))}
                className="w-5 h-5 text-gray-500 ml-auto cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>
        </Transition>
      );
  }
};

export default Toast;
