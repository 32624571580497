import api from './index';

class FileService {
  getPrivateFile(file_path) {
    return api.get(`file_storage/get_private_file?path=${file_path}`).then((response) => {
      return response.data;
    });
  }
}

export default new FileService();
