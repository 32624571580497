import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Menu, Tab, Transition } from '@headlessui/react';
import { ModalDiscard } from '../../../components/Modal';

import Image from '../../../components/Image';

import { showToast } from '../../../store/slices/components.slice';
import { getVendor, updateVendor } from '../../../store/slices/vendor.slice';
import { createImpersonateSession } from '../../../store/slices/admin.slice';

import Profile from './Profile';
import Products from './Products';
import Orders from './Orders';

const VendorDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { vendor_id } = useParams();
  const { vendorApi } = useSelector((state) => state);
  const [currentTab, setCurrentTab] = useState(0);
  const [profile, setProfile] = useState(null);
  const [profileStatus, setProfileStatus] = useState('');
  const [modalOpen, setModulOpen] = useState(false);

  useEffect(() => {
    switch (new URLSearchParams(location.search).get('view')) {
      case 'products':
        setCurrentTab(1);
        break;
      case 'orders':
        setCurrentTab(2);
        break;
      default:
        setCurrentTab(0);
    }
    handleGetVendor(vendor_id);
  }, []);

  const handleGetVendor = (vendor_id) => {
    dispatch(getVendor(vendor_id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setProfile(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleUpdateVendor = (data) => {
    dispatch(updateVendor({ vendorId: vendor_id, data: data }))
      .unwrap()
      .then(async (result) => {
        const { message } = result;
        dispatch(showToast({ show: true, type: 'success', message: message }));
        handleGetVendor(vendor_id);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleModal = () => {
    switch (profile.status) {
      case 'PENDING':
        handleUpdateVendor({ action: 'APPROVE' });
        break;
      case 'ONLINE':
        handleUpdateVendor({ action: 'SUSPEND' });
        break;
      default:
        handleUpdateVendor({ action: 'ONLINE' });
    }
    setModulOpen(false);
  };

  const tabs = [
    {
      id: 0,
      key: 'profile',
      title: 'Profile',
      action: () => history.push(`${location.pathname}?view=profile`),
      panel: <Profile id={vendor_id} profile={profile} hasUpdate={() => handleGetVendor(vendor_id)} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      id: 1,
      key: 'products',
      title: 'Products',
      action: () => history.push(`${location.pathname}?view=products`),
      panel: <Products id={vendor_id} profile={profile} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      key: 'orders',
      title: 'Orders',
      action: () => history.push(`${location.pathname}?view=orders`),
      panel: <Orders id={vendor_id} profile={profile} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <>
      <ul class="flex items-center space-x-2">
        <li class="text-gray-500 text-sm font-medium">
          <Link to={'/vendors'}>Vendors</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        {vendorApi.loading ? (
          <div className="animate-pulse h-4 w-24 bg-gray-400 rounded flex-shrink-0"></div>
        ) : (
          <li class="text-gray-900 text-sm font-medium">{profile?.storeName || vendor_id}</li>
        )}
      </ul>
      <div class="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex items-center p-6 px-4 sm:px-6">
          {vendorApi.loading ? (
            <div className="animate-pulse bg-gray-400 rounded-full h-16 w-16 flex-shrink-0" />
          ) : (
            <Link
              className="relative overflow-hidden rounded-full ring-1 ring-black ring-opacity-5 flex-shrink-0"
              to={{
                pathname: `${location.pathname}/photos/profile`,
                state: profile,
              }}
            >
              <div className="absolute flex items-center justify-center h-16 w-16 hover:bg-black hover:bg-opacity-70 text-xs text-center hover:text-white text-transparent font-semibold">
                View all sizes
              </div>
              {profile?.storeProfilePic ? (
                <Image
                  className="h-16 w-16 object-cover object-center ring-1 ring-black ring-opacity-10 flex-shrink-0"
                  src={profile?.storeProfilePic?.path?.s}
                  alt="storeProfilePic-size-S"
                />
              ) : (
                <div className="rounded-full bg-primary-100 p-2 h-16 w-16 ring-1 ring-primary-600 ring-opacity-10">
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                    ></path>
                  </svg>
                </div>
              )}
            </Link>
          )}
          <div className="flex items-start w-full ml-4">
            <div>
              {vendorApi.loading ? (
                <div className="animate-pulse h-8 w-40 bg-gray-400 rounded" />
              ) : (
                <div className="flex items-center">
                  <h3 class="text-xl leading-6 font-bold text-gray-900">{profile?.storeName}</h3>
                  <p class="ml-3 max-w-2xl text-sm font-medium text-gray-500">
                    <dd class="mt-2 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
                            ${profile?.status === 'REGISTER' ? 'bg-blue-100 text-blue-600' : ''}
                            ${profile?.status === 'PENDING' ? 'bg-primary-100 text-primary-600' : ''}
                            ${profile?.status === 'ONLINE' ? 'bg-green-100 text-green-600' : ''}
                            ${profile?.status === 'SUSPEND' ? 'bg-red-100 text-red-600' : ''}
                          `}
                      >
                        {profile?.status}
                      </span>
                    </dd>
                  </p>
                </div>
              )}
              {vendorApi.loading ? (
                <div className="mt-1 animate-pulse h-4 w-80 bg-gray-400 rounded"></div>
              ) : (
                <div>
                  <p className="mt-1 max-w-2xl text-xs text-gray-500">
                    ID: <span className="font-mono">{vendor_id}</span>
                  </p>
                  <p>
                    <button
                      onClick={() => {
                        dispatch(createImpersonateSession({ user: profile.user.email }))
                          .unwrap()
                          .then((result) => {
                            const { data } = result;
                            const navigateUrl = `${process.env.REACT_APP_NUBES_BASE_URL}callback-redirect-access?token=${data.token}`;
                            window.open(navigateUrl, '_blank').focus();
                          })
                          .catch(({ message }) => {
                            dispatch(showToast({ show: true, type: 'error', message: message }));
                          });
                      }}
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Impersonate User
                    </button>
                  </p>
                </div>
              )}
            </div>
            <div className="ml-auto">
              <Menu as="div" className="ml-auto relative inline-block text-left bg-white">
                <div>
                  <Menu.Button>
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      ></path>
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <div className="px-1 py-0.5">
                      {['PENDING'].includes(profile?.status) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={vendorApi.loading}
                              onClick={() => {
                                setModulOpen(true);
                                setProfileStatus('APPROVE');
                              }}
                              type="button"
                              className={`${
                                active ? 'bg-green-100 text-green-600' : 'text-gray-900'
                              } disabled:opacity-30 disabled:cursor-not-allowed group flex rounded-md items-center w-full px-2 py-2 text-sm focus:outline-none`}
                            >
                              <svg class="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                ></path>
                              </svg>
                              Approve
                            </button>
                          )}
                        </Menu.Item>
                      )}

                      {['ONLINE'].includes(profile?.status) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={vendorApi.loading}
                              onClick={() => {
                                setModulOpen(true);
                                setProfileStatus('SUSPEND');
                              }}
                              type="button"
                              className={`${
                                active ? 'bg-red-100 text-red-600' : 'text-gray-900'
                              } disabled:opacity-30 disabled:cursor-not-allowed group flex rounded-md items-center w-full px-2 py-2 text-sm focus:outline-none`}
                            >
                              <svg class="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                ></path>
                              </svg>
                              Suspend
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      {['SUSPEND'].includes(profile?.status) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={vendorApi.loading}
                              onClick={() => {
                                setModulOpen(true);
                                setProfileStatus('ONLINE');
                              }}
                              type="button"
                              className={`${
                                active ? 'bg-green-100 text-green-600' : 'text-gray-900'
                              } disabled:opacity-30 disabled:cursor-not-allowed group flex rounded-md items-center w-full px-2 py-2 text-sm focus:outline-none`}
                            >
                              <svg class="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                              </svg>
                              Online
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <ModalDiscard
                show={modalOpen}
                title="Confirmation"
                description="Are you sure to perform this action?"
                loading={vendorApi.loading}
                closeable={false}
                confirmText="Proceed"
                cancelText="Cancel"
                confirmAction={handleModal}
                closeAction={() => setModulOpen(false)}
              >
                <div className="w-full">
                  <div className="px-4 py-5 space-y-6 sm:p-6">
                    <p>
                      Continue
                      <span className="font-bold text-gray-900"> {profileStatus} </span>
                      for this vendor.
                    </p>
                  </div>
                </div>
              </ModalDiscard>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Tab.Group defaultIndex={currentTab}>
            <Tab.List className="flex space-x-4 border-b pb-4 px-4 sm:px-6">
              {tabs.map((tab, index) => (
                <Tab key={index} as={Fragment}>
                  {({ selected }) => (
                    <button
                      type="button"
                      onClick={tab.action}
                      className={
                        selected
                          ? 'flex items-center bg-primary-100 text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                          : 'flex items-center text-gray-500 hover:bg-primary-100 hover:text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                      }
                    >
                      {tab.title}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab, index) => (
                <Tab.Panel className="focus:outline-none">{tab.panel}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
