const Home = () => {
  return (
    <>
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-bold text-gray-900">Hi, User!</h2>
      </div>
    </>
  );
};

export default Home;
