import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderService from '../../services/order.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const listVendorOrders = createAsyncThunk('orders/list_orders', async ({ vendorUid, startDate, endDate }, { rejectWithValue }) => {
  try {
    return await orderService.listVendorOrders({ vendorUid, startDate, endDate });
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const listUserOrders = createAsyncThunk('orders/list_orders', async ({ userUid, startDate, endDate }, { rejectWithValue }) => {
  try {
    return await orderService.listUserOrders({ userUid, startDate, endDate });
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getVendorOrder = createAsyncThunk('orders/get_order', async (id, { rejectWithValue }) => {
  try {
    return await orderService.getVendorOrder(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUserOrder = createAsyncThunk('orders/get_order', async (id, { rejectWithValue }) => {
  try {
    return await orderService.getUserOrder(id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { reducer } = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: {
    /** List orders reducer **/
    [listVendorOrders.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listVendorOrders.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listVendorOrders.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** List user orders reducer **/
    [listUserOrders.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listUserOrders.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listUserOrders.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get order reducer **/
    [getVendorOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getVendorOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getVendorOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get order reducer **/
    [getUserOrder.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getUserOrder.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getUserOrder.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
