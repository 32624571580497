import api from './index';

class UserService {
  listUser() {
    return api.get('/user').then((response) => {
      return response.data;
    });
  }

  getUser(userId) {
    return api.get(`/user/${userId}`).then((response) => {
      return response.data;
    });
  }
}

export default new UserService();
