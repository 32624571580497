import axios from 'axios';
import qs from 'querystring';
import { useHistory } from 'react-router';
import authService from './auth.service';
import TokenService from './token.service';

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    if (config.url === '/auth/login') {
      const { email, password } = config.data;
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      config.headers['Authorization'] = `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`; // for Login api only
      config.headers['Content-Type'] = 'application/json';
    } else if (config.url === '/oauth2/token') {
      config.baseURL = process.env.REACT_APP_AUTH_BASE_URL;
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        if (config.headers['content-type'] !== 'application/octet-stream') {
          config.baseURL = process.env.REACT_APP_API_BASE_URL;
          config.headers['Authorization'] = 'Bearer ' + token; // for any other Bearer required api
          config.headers['Content-Type'] = 'application/json';
        } else {
          // File upload API requires 'application/octet-stream' Content-Type header and no Authorization header
          delete config.headers['Authorization'];
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    // const history = useHistory();
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          let refreshToken = TokenService.getLocalRefreshToken();
          let urlencodedData = qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
          });
          const resp = await instance.post('/oauth2/token', urlencodedData);
          const { data } = resp;
          TokenService.updateLocalAccessToken(data.access_token);
          return instance(originalConfig);
        } catch (_error) {
          const { data } = _error.response;
          if (data.error === 'invalid_grant') authService.logout(useHistory);
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
