import { createSlice, createAsyncThunk, prepareAutoBatched } from '@reduxjs/toolkit';
import adminService from '../../services/admin.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const createImpersonateSession = createAsyncThunk('admin/create_impersonate_session', async ({ user }, { rejectWithValue }) => {
  try {
    return await adminService.createImpersonateSession({ user });
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { reducer } = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: {
    /** List admin createImpersonateSession reducer **/
    [createImpersonateSession.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [createImpersonateSession.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [createImpersonateSession.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
