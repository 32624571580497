import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactJson from 'react-json-view';

import { showToast } from '../../../../store/slices/components.slice';
import { getUserOrder } from '../../../../store/slices/order.slice';

import { statusFormatter, classNames } from '../../../../utils';
import { Rate } from 'antd';

const OrderInfo = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const { orderApi } = useSelector((state) => state);

  useEffect(() => {
    handleGetProduct(orderId);
  }, []);

  const handleGetProduct = (id) => {
    dispatch(getUserOrder(id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setOrder(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <LoadingScreen show={orderApi.loading} />
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium">
          <Link to="/user">Users</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-500 text-sm font-medium truncate">
          <Link to={`/user/${order?.userUid}?view=profile`}>{order?.customer?.name}</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-500 text-sm font-medium">
          <Link to={`/user/${order?.userUid}?view=orders`}>Orders</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium">{orderId}</li>
      </ul>
      <div className="space-y-6 max-w-3xl mx-auto py-6 mb-20 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md">
          <div className="grid grid-cols-4 py-6 px-4 sm:px-6 gap-4 sm:gap-4">
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Order ID</span>
              <p className="text-sm text-gray-500">{orderId}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Status</span>
              <p className="text-sm text-500" style={{ color: statusFormatter(order?.status).color }}>
                {statusFormatter(order?.status)?.text}
              </p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Customer</span>
              <p className="text-sm text-gray-500 capitalize">{order?.customer?.name}</p>
              <p className="text-sm text-gray-500">{order?.customer?.phoneNumber}</p>
              <p className="text-sm text-gray-500">{order?.customer?.email}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Delivery Date</span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('DD MMM YYYY')}</p>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <span className="font-medium text-sm">Delivery Time</span>
              <p className="text-sm text-gray-500">{moment(`${order?.deliverDate} ${order?.deliverTime}`).format('hh:mm A')}</p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">Delivery Address </span>
              <p className="text-sm text-gray-500">{`${order?.address1}, ${order?.address2}, ${order?.postalCode} ${order?.city}, ${order?.state}`}</p>
              <p className="text-xs mt-1 font-medium">{`(${order?.location?.lat}, ${order?.location?.lon}) ~ ${order?.distance?.toFixed(2)} KM`}</p>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <span className="font-medium text-sm">
                <Link to={`/vendors/${order?.vendorUid}?view=profile`}>Vendor </Link>
              </span>
              <p className="text-sm text-gray-500">{order?.vendorStoreName}</p>
              <p className="text-sm text-gray-500">{order?.vendorUid}</p>
            </div>
          </div>
          <div className={classNames(order?.status === 'DELIVERED' || order?.status === 'COMPLETED' ? 'block' : 'hidden')}>
            <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4 ">
              <div className="grid grid-cols-4  sm:px-6 gap-4 sm:gap-4 ">
                <div className="col-span-4 sm:col-span-2 ">
                  <p className="font-medium">Rate</p>
                  <Rate disabled value={order.reviewRating} />
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <p className="font-medium">Comment</p>
                  <p className="mt-1 text-gray-500">{order.reviewComment || ''}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t flex-1 py-6 overflow-y-auto px-4 sm:px-6">
            <div>
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {order?.products &&
                    order?.products.map((product, index) => (
                      <li key={index} className="py-6 flex">
                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                          <img src={product.info.picture.path.s} alt={product.info.name} className="w-full h-full object-center object-cover" />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>{product.info.name}</h3>
                              <p className="ml-4">RM {product.price.toFixed(2)}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-500">Qty {product.quantity}</p>
                          </div>
                          <div className="flex-1 flex items-end justify-between text-sm">
                            <p className="text-gray-900 font-medium">
                              Remark: <span className="text-gray-500 font-normal">{product.remark || 'Not provided'}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="text-sm text-gray-900">
                <p className="font-medium">Special instruction:</p>
                <p className="mt-1 text-gray-500">{order?.orderRemark || 'Not provided'}</p>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 py-6 px-4 sm:px-6 space-y-4">
            <div>
              <div className="flex justify-between text-xl font-medium text-gray-900">
                <p>Total</p>
                <p className="font-bold">RM {order?.totalPrice?.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-3xl mx-auto border rounded-lg bg-white shadow-md">
          <h1>JSON Viewer</h1>
          <ReactJson
            src={order}
            name={null}
            //theme="monokai"
          />
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
