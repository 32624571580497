import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import profileService from '../../services/profile.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const getProfile = createAsyncThunk('profile/get_profile', async (_, { rejectWithValue }) => {
  try {
    return await profileService.getProfile();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProfile = createAsyncThunk('profile/update_profile', async (data, { rejectWithValue }) => {
  try {
    return await profileService.updateProfile(data);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    /** Get profile reducer **/
    [getProfile.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getProfile.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getProfile.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Update profile reducer **/
    [updateProfile.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [updateProfile.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [updateProfile.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
