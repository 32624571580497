const Login = () => {
  return (
    <div class="min-h-screen flex items-center justify-center bg-primary-100 py-12 px-4 sm:px-6 lg:px-8">
      <div>
        <div class="relative p-8">
          <a
            href={`${process.env.REACT_APP_AUTH_BASE_URL}/login?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`}
            class="z-10 absolute top-[1.6rem] left-10 active:left-8 active:top-8 px-4 py-3 border-4 rounded-xl border-black bg-primary-400 active:bg-primary-500 font-mono"
          >
            Press here to log in
          </a>
          <div class="px-4 py-3 h-14 w-[14.37rem] border-4 rounded-xl border-black bg-black font-mono"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
