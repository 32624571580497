import api from './index';

class VendorService {
  listVendors() {
    return api.get('/vendor').then((response) => {
      return response.data;
    });
  }

  getVendor(vendorId) {
    return api.get(`/vendor/${vendorId}`).then((response) => {
      return response.data;
    });
  }

  updateVendor({ vendorId, data }) {
    return api.put(`/vendor/${vendorId}`, data).then((response) => {
      return response.data;
    });
  }

  listVendorProducts(vendorId) {
    return api.get(`/vendor/${vendorId}/product`).then((response) => {
      return response.data;
    });
  }

  getVendorProduct({ vendorId, productId }) {
    return api.get(`/vendor/${vendorId}/product/${productId}`).then((response) => {
      return response.data;
    });
  }

  updateVendorProduct({ vendorId, productId, data }) {
    return api.put(`/vendor/${vendorId}/product/${productId}`, data).then((response) => {
      return response.data;
    });
  }
}

export default new VendorService();
