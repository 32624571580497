import imgNotFound from '../../assets/images/img-not-found.png';

const Image = ({ alt = '', src, ...rest }) => {
  return (
    <img
      {...rest}
      src={src || imgNotFound}
      alt={alt}
      onError={(e) => {
        e.target.src = imgNotFound;
        e.target.classList.add('opacity-80', 'p-1');
      }}
    />
  );
};

export default Image;
