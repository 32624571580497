import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import tokenService from '../../services/token.service';
import authService from '../../services/auth.service';

import { showToast } from '../../store/slices/components.slice';

import api from '../../services';

const Callback = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let code = new URLSearchParams(location.search).get('code');

  useEffect(() => {
    authService.logout(history);
    handleCallback(code);
  }, []);

  const handleCallback = async (code) => {
    api
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/oauth2/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&code=${code}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
        null,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(({ data }) => {
        tokenService.setToken(data);
        dispatch(showToast({ show: true, type: 'success', message: `You've successfully log in` }));
        history.push('/');
      });
  };

  return <div className="sr-only">Callback</div>;
};

export default Callback;
