import { Switch } from 'react-router-dom';
import { PublicRoute, ProtectedRoute } from './components/Routes';
import Toast from './components/Toast';
import Login from './pages/Login';
import Callback from './pages/Callback';
import Dashboard from './pages/Dashboard';
import { useSelector } from 'react-redux';
import Vendors from './pages/Dashboard/Vendors';

const App = () => {
  const { components } = useSelector((state) => state);
  const toast = components.toast;

  return (
    <>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/callback" component={Callback} />
        <ProtectedRoute path="/" component={Dashboard} />
        <ProtectedRoute path="/vendors" component={Vendors} />
      </Switch>
      <Toast show={toast.show} type={toast.type} subject={toast.subject} message={toast.message} />
    </>
  );
};

export default App;
