import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactJson from 'react-json-view';

import { showToast } from '../../../../store/slices/components.slice';
import { getPrivateFile } from '../../../../store/slices/file.slice';
import { updateVendor } from '../../../../store/slices/vendor.slice';

import { chunkingOperationHours, classNames, ServiceChargePercentage } from '../../../../utils';
import Image from '../../../../components/Image';
import { Form, Input } from '../../../../components/Form';

const Profile = ({ profile, id, hasUpdate }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { vendorApi } = useSelector((state) => state);
  const [serviceChargePercentageChange, setServiceChargePercentageChange] = useState(false);

  const days = [
    { name: 'Sun', value: 'sunday' },
    { name: 'Mon', value: 'monday' },
    { name: 'Tue', value: 'tuesday' },
    { name: 'Wed', value: 'wednesday' },
    { name: 'Thu', value: 'thursday' },
    { name: 'Fri', value: 'friday' },
    { name: 'Sat', value: 'saturday' },
  ];

  const chunkedOperationHours =
    profile?.storeOperationSchedules &&
    Object.keys(profile?.storeOperationSchedules).reduce((acc, key) => {
      acc[key] = chunkingOperationHours(profile?.storeOperationSchedules[key]);
      return acc;
    }, {});

  const handleGetPrivateFile = (file_path) => {
    dispatch(getPrivateFile(file_path))
      .unwrap()
      .then(async (result) => {
        const { signUrl } = result.data;
        const win = window.open(signUrl, '_blank');
        win.focus();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleUpdateVendor = (data) => {
    dispatch(updateVendor({ vendorId: id, data: data }))
      .unwrap()
      .then(async (result) => {
        const { message } = result;
        dispatch(showToast({ show: true, type: 'success', message: message }));
        window.location.reload();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <dl className="divide-y">
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Service Fee Percentage</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <>
              {profile?.serviceChargePercentage || profile?.serviceChargePercentage == 0 ? (
                <>
                  <dd class="font-bold text-gray-600">{profile?.serviceChargePercentage}% - Custom</dd>
                </>
              ) : (
                <>
                  <dd class="font-bold text-gray-600">{ServiceChargePercentage}% - Default</dd>
                </>
              )}

              <Form
                onSubmit={(item) => {
                  const numberServiceChargePercentage = Number(item.serviceChargePercentage);
                  if (!(Number.isInteger(item.serviceChargePercentage) && numberServiceChargePercentage >= 0 && numberServiceChargePercentage <= 50)) {
                    dispatch(showToast({ show: true, type: 'error', message: 'Service Fee Percentage must valid number between 0 to 50' }));
                    return;
                  }
                  handleUpdateVendor({ serviceChargePercentage: numberServiceChargePercentage });
                }}
              >
                <Input.Number
                  name="serviceChargePercentage"
                  placeholder={profile?.serviceChargePercentage || profile?.serviceChargePercentage == 0 ? `${profile?.serviceChargePercentage}%` : `15%`}
                  disabled={vendorApi.loading}
                  rules={[{ required: true, message: 'This field is required' }]}
                  type="Integer"
                  retrieveValue={(event) => {
                    if (event.target.value && Number.isInteger(Number(event.target.value))) {
                      setServiceChargePercentageChange(true);
                      return;
                    }
                    setServiceChargePercentageChange(false);
                  }}
                />
                <button
                  type="submit"
                  disabled={!serviceChargePercentageChange}
                  className="disabled:opacity-50 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  save
                </button>
              </Form>
            </>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Store banner photo</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse bg-gray-400 rounded w-[400px] h-[100px]" />
          ) : (
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Link
                className="relative inset-0 overflow-hidden ring-1 ring-black ring-opacity-5 flex-shrink-0 rounded-lg"
                to={{
                  pathname: `${location.pathname}/photos/banner`,
                  state: profile,
                }}
              >
                <div className="absolute inset-0 flex items-center justify-center hover:bg-black hover:bg-opacity-70 text-sm text-center hover:text-white text-transparent font-semibold">
                  View all sizes
                </div>
                {profile?.storeBannerPic ? (
                  <Image
                    className="w-[400px] h-[100px] object-cover object-center  ring-1 ring-black ring-opacity-10"
                    src={profile?.storeBannerPic?.path?.s}
                    alt="storeBannerPic-size-S"
                  />
                ) : (
                  <div className="w-[400px] h-[100px] object-cover object-center bg-primary-100 ring-1 ring-black ring-opacity-10">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                      ></path>
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                      ></path>
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                      ></path>
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                      ></path>
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                      ></path>
                    </svg>
                  </div>
                )}
              </Link>
            </dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Store name</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.storeName}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Promo Code</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <>
              <Form
                onSubmit={(item) => {
                  const { customerDiscount } = item;
                  customerDiscount.code = customerDiscount.code.toUpperCase();
                  handleUpdateVendor({ customerDiscount });
                }}
              >
                <Input.Text
                  label="Code"
                  name="customerDiscount.code"
                  placeholder={profile?.customerDiscount?.code}
                  disabled={vendorApi.loading}
                  rules={[{ required: true, message: 'This field is required' }]}
                />
                <Input.Text
                  label="Message"
                  name="customerDiscount.message"
                  placeholder={profile?.customerDiscount?.message}
                  disabled={vendorApi.loading}
                  rules={[{ required: true, message: 'This field is required' }]}
                />
                <button
                  type="submit"
                  className="disabled:opacity-50 w-1/2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  save
                </button>
                <button
                  type="button"
                  disabled={!profile?.customerDiscount}
                  onClick={() => {
                    handleUpdateVendor({ customerDiscount: { delete: true } });
                  }}
                  className="disabled:opacity-50 w-1/2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Delete
                </button>
              </Form>
            </>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Username</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <>
              {profile?.username ? (
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.username}</dd>
              ) : (
                <dd class="mt-1 flex text-sm text-red-500 sm:mt-0 sm:col-span-2">No Username</dd>
              )}
            </>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Store description</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.storeDescription}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Store address</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <table className="prose prose-sm">
                <tbody>
                  <tr>
                    <td className="font-medium">Address Line 1</td>
                    <td>{profile?.storeAddress1 || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Address Line 2</td>
                    <td>{profile?.storeAddress2 || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Postal Code</td>
                    <td>{profile?.storePostalCode || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">City</td>
                    <td>{profile?.storeCity || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">State</td>
                    <td>{profile?.storeState || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Location</td>
                    <td>{`${profile?.storeLocationLatLong?.lat}, ${profile?.storeLocationLatLong?.lon}` || 'Not provided'}</td>
                    <td>
                      <a
                        href={`https://maps.google.com/?q=${profile?.storeLocationLatLong?.lat},${profile?.storeLocationLatLong?.lon}`}
                        target="_blank"
                        rel="noreferrer"
                        className="ml-auto font-sans font-medium text-primary-600 hover:text-primary-500"
                      >
                        <button type="button" className="ml-auto font-sans font-medium text-primary-600 hover:text-primary-500">
                          View map
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Total load capacity</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.totalLoadCapacity}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Availability distance (KM)</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.availabilityRadiusKm}</dd>
          )}
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Delivery price tier</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                Enable Delivery Price Tier: <b>{profile?.storeDeliveryPriceEnable ? 'Enable ✅' : 'Disable ❌'}</b>
              </dd>
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="bg-gray-50 px-2 py-0 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Begin (KM)
                      </th>
                      <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        End (KM)
                      </th>
                      <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Price (RM)
                      </th>
                      <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y bg-white">
                    {profile?.storeDeliveryPriceTier?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-0 whitespace-nowrap text-left text-sm">{item.begin}</td>
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.end}</td>
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{item.price}</td>
                        <td className="px-0 py-0 whitespace-nowrap text-left text-sm"></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </dd>
          )}
        </div>

        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Operation Hours</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd className="mt-4 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ring-1 ring-black ring-opacity-5 shadow rounded-md overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Day
                    </th>
                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {days.map((day, index) => (
                    <tr key={index}>
                      <td className="px-6 py-3 whitespace-nowrap text-left text-sm">{day.name}</td>
                      {profile?.storeOperationSchedules && profile.storeOperationSchedules[day.value].length > 0 ? (
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">
                          {chunkedOperationHours[day.value].map((hours, index) => {
                            if (hours.length > 1)
                              return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')} - ${moment(hours[hours.length - 1], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                            else return <p key={index}>{`${moment(hours[0], 'HH:mm:ss').format('hh:mm A')}`}</p>;
                          })}
                        </td>
                      ) : (
                        <td className="px-6 py-3 whitespace-nowrap text-left text-sm">Closed</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Company name</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.companyName || 'Not provided'}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Company registration number</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.companyNumber || 'Not provided'}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Company SSM certificate</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : profile?.companySSMCert ? (
            <dd className="mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        fillRule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <button
                      type="button"
                      onClick={() => handleGetPrivateFile(profile?.companySSMCert?.path)}
                      className="ml-2 truncate text-primary-600 dark:text-primary-500 hover:text-primary-700 dark:hover:text-primary-600 font-medium border-b border-dotted border-primary-600 dark:border-primary-500 hover:border-primary-700 dark:hover:border-primary-600"
                    >
                      {`${profile?.companySSMCert?.path?.split('/')[2]}.${profile?.companySSMCert?.path?.split('/')[3].split('.')[1]}`}
                    </button>
                  </div>
                </li>
              </ul>
            </dd>
          ) : (
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Not provided</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Admin name</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.adminName || 'Not provided'}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Admin contact number</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <a href={`https://wa.me/${profile?.adminContactNumber}`} target="_blank">
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 hover:text-primary-700">{profile?.adminContactNumber || 'Not provided'}</dd>
            </a>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">User</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <table className="prose prose-sm">
                <tbody>
                  <tr>
                    <td className="font-medium">UID</td>
                    <td className={classNames(profile?.user?.uid && 'font-mono')}>{profile?.user?.uid || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Name</td>
                    <td>{profile?.user?.name || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Email</td>
                    <td>{profile?.user?.email || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Phone number</td>
                    <td className="font-medium">
                      <a
                        href={`https://wa.me/${profile?.user?.phoneNumber}`}
                        target="_blank"
                        className="text-sm font-medium  hover:text-primary-700"
                        style={{ textDecoration: 'none', fontWeight: 'normal' }}
                      >
                        {profile?.user?.phoneNumber || 'Not provided'}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Stripe account</dt>
          {vendorApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-11/12 max-w-prose">
              <table className="prose prose-sm">
                <tbody>
                  <tr>
                    <td className="font-medium">ID</td>
                    <td className={classNames(profile?.stripeAccountId && 'font-mono')}>{profile?.stripeAccountId || 'N/A'}</td>
                  </tr>
                  <p class="text-sm font-medium text-gray-500">Business profile</p>
                  <tr>
                    <td className="font-medium">MCC</td>
                    <td>{profile?.stripeAccount?.business_profile?.mcc || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Name</td>
                    <td>{profile?.stripeAccount?.business_profile?.name || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Support address</td>
                    <td>{profile?.stripeAccount?.business_profile?.support_address || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Support email</td>
                    <td>{profile?.stripeAccount?.business_profile?.support_email || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Support phone</td>
                    <td>
                      <a
                        href={`https://wa.me/${profile?.user?.phoneNumber}`}
                        target="_blank"
                        className="text-sm font-medium text-primary-600 hover:text-primary-700"
                        style={{ textDecoration: 'none', fontWeight: 'normal' }}
                      >
                        {profile?.stripeAccount?.business_profile?.support_phone || 'N/A'}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-medium">Support URL</td>
                    <td>{profile?.stripeAccount?.business_profile?.support_url || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">URL</td>
                    <td>{profile?.stripeAccount?.business_profile?.url || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Account completed?</td>
                    <td>{profile?.stripeAccount?.payouts_enabled ? 'Yes' : 'No (Pending)'}</td>
                  </tr>
                </tbody>
              </table>
              {profile?.stripeAccount?.requirements?.errors?.length > 0 && (
                <div>
                  <div className="flex mt-1 sm:mt-0 sm:col-span-2">
                    <p className="text-sm font-medium text-red-600">
                      <b>Stripe message:</b> {profile?.stripeAccount?.requirements?.errors[0]?.reason}
                      <br />
                    </p>
                  </div>
                </div>
              )}
            </dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium  text-gray-500">JSON Viewer</dt>
          <dt class="col-span-2">
            <ReactJson src={profile} name={null} theme="monokai" collapsed={true} />
          </dt>
        </div>
      </dl>
    </>
  );
};

export default Profile;
