import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../../../components/Table';
import Image from '../../../components/Image';

import { showToast } from '../../../store/slices/components.slice';
import { listVendors } from '../../../store/slices/vendor.slice';

import VendorDetails from './Details';
import VendorBannerPhotos from './Photos/Banner';
import VendorProfilePhotos from './Photos/Profile';
import VendorProductDetails from './Products/Details';
import OrderInfo from './Orders/OrderInfo';

const Vendors = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { vendorApi } = useSelector((state) => state);

  useEffect(() => {
    handleListVendors();
  }, []);

  const handleListVendors = () => {
    dispatch(listVendors())
      .unwrap()
      .then((result) => {
        const { data } = result;
        setTableData(data?.vendors);
      })
      .catch((result) => {
        const { error, message } = result;
        dispatch(showToast({ show: true, type: 'error', message: error || message }));
      });
  };

  const tableHeaders = [
    {
      title: 'Store name',
      objPropKey: 'uid',
      render: (uid, { storeName, storeProfilePic, username }) => (
        <div class="flex items-center">
          {storeProfilePic ? (
            <Image
              src={storeProfilePic?.path?.s}
              alt={`${storeName}-vendorPhoto`}
              className="h-[48px] w-[48px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
            />
          ) : (
            <div className="rounded-full bg-primary-100 p-2 w-[48px] h-[48px] ring-1 ring-primary-600 ring-opacity-10">
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                ></path>
              </svg>
            </div>
          )}
          <Link className="ml-4" to={`/vendors/${uid}`}>
            <div class="text-sm font-medium text-primary-600 hover:text-primary-700">{storeName || uid}</div>
            {username ? <div class="mt-1 text-xs text-gray-500">@{username}</div> : <div class="mt-1 text-xs text-red-500">No Username</div>}
          </Link>
        </div>
      ),
    },
    {
      title: 'Admin Info',
      objPropKey: 'adminName',
      render: (adminName, { adminContactNumber }) => (
        <div class="flex items-center">
          <div>
            <div class="text-sm font-medium text-gray-700">{adminName}</div>
            <div class="mt-1 text-xs text-gray-500">{adminContactNumber}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      objPropKey: 'status',
      filters: ['REGISTER', 'PENDING', 'ONLINE', 'SUSPEND'],
      render: (status) => (
        <span
          class={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md  
            ${status === 'REGISTER' ? 'bg-blue-100 text-blue-600' : ''}
            ${status === 'PENDING' ? 'bg-primary-100 text-primary-600' : ''}
            ${status === 'ONLINE' ? 'bg-green-100 text-green-600' : ''}
            ${status === 'SUSPEND' ? 'bg-red-100 text-red-600' : ''}
          `}
        >
          {status}
        </span>
      ),
    },
  ];

  return (
    <>
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-bold text-gray-900">Vendors</h2>
      </div>
      <div className="mt-6 flex flex-col">
        <Table dataInput={tableData} headers={tableHeaders} pagination={{ limit: 10, pageNeighbours: 1 }} loading={vendorApi.loading} enableFilter={true}>
          <div className="flex flex-col items-center justify-center py-10 bg-white w-full text-gray-600">
            <svg class="w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"></path>
              <path
                fill-rule="evenodd"
                d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h3 className="mt-2 font-medium">No result found</h3>
          </div>
        </Table>
      </div>
    </>
  );
};

Vendors.Details = VendorDetails;
Vendors.BannerPhotos = VendorBannerPhotos;
Vendors.ProfilePhotos = VendorProfilePhotos;
Vendors.ProductDetails = VendorProductDetails;
Vendors.OrderInfo = OrderInfo;

export default Vendors;
