import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import Image from '../../../../components/Image';
import { showToast } from '../../../../store/slices/components.slice';
import { getVendorProduct, updateVendorProduct } from '../../../../store/slices/vendor.slice';
import { ModalDiscard } from '../../../../components/Modal';
import ReactJson from 'react-json-view';

const VendorProductDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { vendor_id, product_id } = useParams();
  const [formData, setFormData] = useState(null);
  const { vendorApi } = useSelector((state) => state);
  const [modalOpen, setModulOpen] = useState(false);
  const [productStatus, setProductStatus] = useState('');

  useEffect(() => {
    handleGetProduct(product_id);
  }, []);

  const handleGetProduct = (product_id) => {
    dispatch(getVendorProduct({ vendorId: vendor_id, productId: product_id }))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setFormData(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleUpdateVendorProduct = (data) => {
    if (data.action) {
      if (data.action === 'APPROVE') {
        dispatch(updateVendorProduct({ vendorId: vendor_id, productId: product_id, data: { ...formData, isApproval: true, ...data } }))
          .unwrap()
          .then((result) => {
            dispatch(showToast({ show: true, type: 'success', message: `Product has been successfully approved!` }));
            history.push(`/vendors/${vendor_id}?view=products`);
          })
          .catch(({ message, validate }) => {
            dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
          });
      } else if (data.action === 'VERIFIED') {
        dispatch(updateVendorProduct({ vendorId: vendor_id, productId: product_id, data: { ...formData, ...data } }))
          .unwrap()
          .then((result) => {
            dispatch(showToast({ show: true, type: 'success', message: `Product has been successfully verified!` }));
            history.push(`/vendors/${vendor_id}?view=products`);
          })
          .catch(({ message, validate }) => {
            dispatch(showToast({ show: true, type: 'error', message: message, errors: validate?.details }));
          });
      }
    }
  };

  const handleModal = () => {
    switch (productStatus) {
      case 'APPROVE':
        handleUpdateVendorProduct({ action: 'APPROVE' });
        break;
      case 'VERIFIED':
        handleUpdateVendorProduct({ action: 'VERIFIED' });
        break;
    }
    setModulOpen(false);
  };

  return (
    <>
      <ul className="flex items-center space-x-2">
        <li className="text-gray-500 text-sm font-medium truncate">
          <Link to="/vendors">Vendors</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-500 text-sm font-medium truncate">
          <Link to={`/vendors/${vendor_id}?view=profile`}>{location?.state?.storeName || vendor_id}</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-500 text-sm font-medium truncate">
          <Link to={`/vendors/${vendor_id}?view=products`}>Products</Link>
        </li>
        <li>
          <svg className="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </li>
        <li className="text-gray-900 text-sm font-medium truncate">{formData?.newData?.name || formData?.name || product_id}</li>
      </ul>
      <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex items-start px-4 py-5 sm:px-6">
          <div>
            {vendorApi.loading ? (
              <div className="animate-pulse h-8 w-40 bg-gray-400 rounded" />
            ) : (
              <div className="flex items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{formData?.newData?.name || formData?.name || vendor_id}</h3>
                {formData?.isApproval ? (
                  formData?.newData ? (
                    <span class="ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-primary-100 text-primary-600 uppercase">Awaiting Verification</span>
                  ) : null
                ) : formData?.newData ? (
                  <span class="ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-rose-100 text-rose-600 uppercase">Awaiting approval</span>
                ) : null}
              </div>
            )}
            {vendorApi.loading ? (
              <div className="mt-1 animate-pulse h-4 w-80 bg-gray-400 rounded"></div>
            ) : (
              <p className="mt-1 max-w-2xl text-xs text-gray-500">
                ID: <span className="font-mono">{product_id}</span>
              </p>
            )}
          </div>
          {(!formData?.isApproval || formData?.newData) && (
            <div className="ml-auto">
              <Menu as="div" className="ml-auto relative inline-block text-left bg-white">
                <div>
                  <Menu.Button>
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      ></path>
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-0 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <div className="px-1 py-0.5">
                      {!formData?.isApproval && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={vendorApi.loading}
                              onClick={() => {
                                setModulOpen(true);
                                setProductStatus('APPROVE');
                              }}
                              type="button"
                              className={`${
                                active ? 'bg-green-100 text-green-600' : 'text-gray-900'
                              } disabled:opacity-30 disabled:cursor-not-allowed group flex rounded-md items-center w-full px-2 py-2 text-sm focus:outline-none`}
                            >
                              <svg class="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                ></path>
                              </svg>
                              Approve
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      {formData?.isApproval && formData?.newData && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              disabled={vendorApi.loading}
                              onClick={() => {
                                setModulOpen(true);
                                setProductStatus('VERIFIED');
                              }}
                              type="button"
                              className={`${
                                active ? 'bg-primary-100 text-primary-600' : 'text-gray-900'
                              } disabled:opacity-30 disabled:cursor-not-allowed group flex rounded-md items-center w-full px-2 py-2 text-sm focus:outline-none`}
                            >
                              <svg class="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                ></path>
                              </svg>
                              Verified
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <ModalDiscard
                show={modalOpen}
                title="Confirmation"
                description="Are you sure to perform this action?"
                loading={vendorApi.loading}
                closeable={false}
                confirmText="Proceed"
                cancelText="Cancel"
                confirmAction={handleModal}
                closeAction={() => setModulOpen(false)}
              >
                <div className="w-full">
                  <div className="px-4 py-5 space-y-6 sm:p-6">
                    <p>
                      Continue
                      <span className="font-bold text-gray-900"> {productStatus} </span>
                      for this product.
                    </p>
                  </div>
                </div>
              </ModalDiscard>
            </div>
          )}
        </div>
        <div className="border-t border-gray-200">
          {formData?.isApproval ? (
            <>
              <div>
                <div className="grid grid-cols-3 gap-6 p-4 md:p-6">
                  <div className="col-span-2 md:col-span-1">
                    <div>
                      <h3 className="text-base font-medium leading-6 text-gray-900">Availability</h3>
                    </div>
                  </div>
                  <div className="ml-auto md:ml-0 col-span-1 md:col-span-2">
                    {formData?.isAvailable ? (
                      <svg class="w-6 h-6 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    ) : (
                      <svg class="w-6 h-6 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div className="block" aria-hidden="true">
                <div className="border-t border-gray-200"></div>
              </div>

              <div>
                <div className="grid grid-cols-3 gap-6 p-4 md:p-6">
                  <div className="col-span-2 md:col-span-1">
                    <div>
                      <h3 className="text-base font-medium leading-6 text-gray-900">Visibility</h3>
                    </div>
                  </div>
                  <div className="ml-auto md:ml-0 col-span-1 md:col-span-2">
                    {formData?.isVisible ? (
                      <svg class="w-6 h-6 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    ) : (
                      <svg class="w-6 h-6 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div className="block" aria-hidden="true">
                <div className="border-t border-gray-200"></div>
              </div>
            </>
          ) : null}
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Product photo</h3>
                </div>
              </div>
              {formData?.picture ? (
                formData?.newData?.picture?.path?.m ? (
                  <div className="mt-4 md:mt-0 md:col-span-2">
                    <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">Current photo</span>
                          <div className="mt-2 relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden w-full sm:w-[300px] h-[300px]">
                            <Image src={formData?.picture?.path?.m} alt="img-preview-current" className="absolute h-full w-full object-cover object-center" />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">New photo</span>
                          <div className="mt-2 relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden w-full sm:w-[300px] h-[300px]">
                            <Image src={formData?.newData?.picture?.path?.m} alt="img-preview-new" className="absolute h-full w-full object-cover object-center" />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : (
                  <div className="mt-4 relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden w-full sm:w-[300px] h-[300px]">
                    <Image src={formData?.picture?.path?.m} alt="img-preview" className="absolute h-full w-full object-cover object-center" />
                  </div>
                )
              ) : (
                <div className="mt-4 relative rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden w-full sm:w-[300px] h-[300px]">
                  <Image src={formData?.newData?.picture?.path?.m} alt="img-preview" className="absolute h-full w-full object-cover object-center" />
                </div>
              )}
            </div>
          </div>

          <div className="block" aria-hidden="true">
            <div className="border-t border-gray-200"></div>
          </div>

          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Product name</h3>
                </div>
              </div>
              {formData?.name ? (
                formData?.newData?.name ? (
                  <div className="mt-4 md:mt-0 md:col-span-2">
                    <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">Current value</span>
                          <p className="mt-2 text-lg">{formData?.name}</p>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">New value</span>
                          <p className="mt-2 text-lg">{formData?.newData?.name}</p>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : (
                  <div className="mt-2 md:mt-0 md:col-span-2">{formData?.name}</div>
                )
              ) : (
                <div className="mt-2 md:mt-0 md:col-span-2">{formData?.newData?.name}</div>
              )}
            </div>
          </div>

          <div className="block" aria-hidden="true">
            <div className="border-t border-gray-200"></div>
          </div>

          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Product price</h3>
                </div>
              </div>
              {formData?.price ? (
                formData?.newData?.price ? (
                  <div className="mt-4 md:mt-0 md:col-span-2">
                    <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">Current value</span>
                          <p className="mt-2 text-lg">RM {formData?.price?.toFixed(2)}</p>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">New value</span>
                          <p className="mt-2 text-lg">RM {formData?.newData?.price?.toFixed(2)}</p>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : (
                  <div className="mt-2 md:mt-0 md:col-span-2">{formData?.price}</div>
                )
              ) : (
                <div className="mt-2 md:mt-0 md:col-span-2">{formData?.newData?.price}</div>
              )}
            </div>
          </div>

          <div className="block" aria-hidden="true">
            <div className="border-t border-gray-200"></div>
          </div>

          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Product description</h3>
                </div>
              </div>
              {formData?.description ? (
                formData?.newData?.description ? (
                  <div className="mt-4 md:mt-0 md:col-span-2">
                    <fieldset className="border border-solid border-gray-300 rounded-lg p-4">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">Current value</span>
                          <p className="mt-2 text-lg">{formData?.description}</p>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <span className="font-medium text-sm">New value</span>
                          <p className="mt-2 text-lg">{formData?.newData?.description}</p>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                ) : (
                  <div className="mt-2 md:mt-0 md:col-span-2">{formData?.description}</div>
                )
              ) : (
                <div className="mt-2 md:mt-0 md:col-span-2">{formData?.newData?.description}</div>
              )}
            </div>
          </div>

          <div className="block" aria-hidden="true">
            <div className="border-t border-gray-200"></div>
          </div>

          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Lead time</h3>
                </div>
              </div>
              <div className="mt-4 md:mt-0 md:col-span-2">{formData?.leadTime}</div>
            </div>
          </div>

          <div className="block" aria-hidden="true">
            <div className="border-t border-gray-200"></div>
          </div>

          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 p-4 md:p-6">
              <div className="md:col-span-1">
                <div>
                  <h3 className="text-base font-medium leading-6 text-gray-900">Load usage</h3>
                </div>
              </div>
              <div className="mt-4 md:mt-0 md:col-span-2">{formData?.loadUsage}</div>
            </div>
          </div>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium  text-gray-500">JSON Viewer</dt>
          <dt class="col-span-2">
            <ReactJson src={formData} name={null} theme="monokai" collapsed={true} />
          </dt>
        </div>
      </div>
    </>
  );
};

export default VendorProductDetails;
