import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Form, Input } from '../../components/Form';
import Layout from '../../components/Layout';
import { Modal } from '../../components/Modal';
import tokenService from '../../services/token.service';
import { showToast } from '../../store/slices/components.slice';
import { getProfile, updateProfile } from '../../store/slices/profile.slice';

import Home from './Home';
import Profile from './Profile';
import Vendors from './Vendors';
import User from './User';

const Dashboard = ({ path }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const formNode = useRef();
  const [firstTimeModal, setFirstTimeModal] = useState(false);
  const { profileApi } = useSelector((state) => state);

  useEffect(() => {
    handleGetUser();
  }, [location]);

  const handleGetUser = () => {
    if (tokenService.getLocalAccessToken()) {
      dispatch(getProfile())
        .unwrap()
        .then(({ data }) => {
          if (!data.name || !data.phoneNumber) {
            setFirstTimeModal(true);
          } else {
            localStorage.setItem('user', JSON.stringify(data));
          }
        })
        .catch(({ message }) => {});
    }
  };

  const handleSubmit = (data) => {
    dispatch(updateProfile(data))
      .unwrap()
      .then(({ message }) => {
        dispatch(showToast({ show: true, type: 'success', message: message }));
        setFirstTimeModal(false);
        handleGetUser();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <div className="bg-white text-gray-700 font-sans antialiased">
        <div className="min-h-screen flex">
          <Layout.Sidebar path={location.pathname} />
          <div className="h-screen flex-1 min-w-0 flex flex-col overflow-auto">
            <Layout.Header />
            <Layout.Content>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/vendors" component={Vendors} />
                <Route exact path="/vendors/:vendor_id" component={Vendors.Details} />
                <Route exact path="/vendors/:vendor_id/photos/profile" component={Vendors.ProfilePhotos} />
                <Route exact path="/vendors/:vendor_id/photos/banner" component={Vendors.BannerPhotos} />
                <Route exact path="/vendors/:vendor_id/product/:product_id" component={Vendors.ProductDetails} />
                <Route exact path="/vendors/:vendor_id/order/:orderId" component={Vendors.OrderInfo} />
                <Route exact path="/user" component={User} />
                <Route exact path="/user/:user_id" component={User.Details} />
                <Route exact path="/user/:user_id/photos/profile" component={User.ProfilePhotos} />
                <Route exact path="/user/:user_id/order/:orderId" component={User.OrderInfo} />
                <Route component={NotFound} />
              </Switch>
            </Layout.Content>
          </div>
        </div>
      </div>
      <Modal
        show={firstTimeModal}
        title="Complete your account"
        description="You're required to complete your account before proceed"
        loading={profileApi.loading}
        closeable={false}
        confirmText="Submit"
        confirmAction={() => formNode.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
      >
        <div className="w-full">
          <Form onSubmit={handleSubmit} setRef={formNode}>
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <Input.Text name="name" label="Name" placeholder="Your name" disabled={profileApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                </div>
                <div className="col-span-6">
                  <Input.Phone
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="e.g 60123456789"
                    disabled={profileApi.loading}
                    rules={[{ required: true, message: 'This field is required' }]}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const NotFound = () => {
  return (
    <section id="protected-page" className="-m-6 sm:-m-8 flex items-center justify-center relative min-h-full">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-7xl">
        <p className="text-base font-semibold tracking-wider text-primary-500 uppercase">404 Error</p>
        <h2 className="mt-2 text-3xl sm:text-5xl font-extrabold text-primary-600 tracking-tight">Not Found.</h2>
        <p className="my-5 text-base text-gray-500 sm:leading-10 sm:text-xl lg:text-lg xl:text-xl">The page that you look for is not found</p>
      </div>
    </section>
  );
};

export default Dashboard;
