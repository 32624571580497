import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '../../../components/Form';
import { showToast } from '../../../store/slices/components.slice';
import { getProfile, updateProfile } from '../../../store/slices/profile.slice';

const Profile = () => {
  const [user, setUser] = useState(null);
  const { profileApi } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetProfile();
  }, []);

  const handleGetProfile = () => {
    dispatch(getProfile())
      .unwrap()
      .then((result) => {
        const { data } = result;
        console.log(data);
        setUser(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const handleSubmit = (data) => {
    const params = {
      name: data?.name,
      phoneNumber: data?.phoneNumber,
    };

    dispatch(updateProfile(params))
      .unwrap()
      .then(({ data }) => {
        dispatch(showToast({ show: true, type: 'success', message: `Your profile updated successfully!` }));
        localStorage.setItem('user', JSON.stringify(data));
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  return (
    <>
      <div class="flex items-center justify-between max-w-xl mx-auto">
        <h2 class="text-2xl font-bold text-gray-900">My profile</h2>
      </div>
      <div className="mt-6 max-w-xl mx-auto">
        <Form onSubmit={handleSubmit} defaultValues={user}>
          <div className="shadow rounded-md overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-4">
                  <h2>Email</h2>
                  <p>{user?.email}</p>
                </div>

                <div className="col-span-4">
                  <Input.Text name="name" label="Name" placeholder="Your name" disabled={profileApi.loading} rules={[{ required: true, message: 'This field is required' }]} />
                </div>

                <div className="col-span-5">
                  <Input.Phone
                    name="phoneNumber"
                    label="Phone number"
                    placeholder="e.g 60123456789"
                    disabled={profileApi.loading}
                    rules={[{ required: true, message: 'This field is required' }]}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Profile;
