import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Image from '../../../../components/Image';

const VendorProfilePhotos = () => {
  const { vendor_id } = useParams();
  const location = useLocation();

  const [profilePhotos, setProfilePhotos] = useState(location.state);

  useState(() => {
    if (!location.state) {
    }
  }, []);

  return (
    <>
      <ul class="flex items-center space-x-2">
        <li class="text-gray-500 text-sm font-medium">
          <Link to={'/vendors'}>Vendors</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        <li class="text-gray-500 text-sm font-medium">
          <Link to={`/vendors/${vendor_id}?view=profile`}>{profilePhotos?.storeName || vendor_id}</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        <li class="text-gray-900 text-sm font-medium">Profile photos</li>
      </ul>
      <div className="mt-6 space-y-8">
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size S</legend>
          {profilePhotos?.storeProfilePic ? (
            <Image
              className="h-[32px] w-[32px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.storeProfilePic?.path?.s}
              alt="storeProfilePic-size-S"
            />
          ) : (
            <div className="rounded-full bg-primary-100 p-2 h-[32px] w-[32px] ring-1 ring-primary-600 ring-opacity-10">
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                ></path>
              </svg>
            </div>
          )}
        </fieldset>
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size M</legend>
          {profilePhotos?.storeProfilePic ? (
            <Image
              className="h-[48px] w-[48px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.storeProfilePic?.path?.m}
              alt="storeProfilePic-size-M"
            />
          ) : (
            <div className="rounded-full bg-primary-100 p-2 h-[48px] w-[48px] ring-1 ring-primary-600 ring-opacity-10">
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                ></path>
              </svg>
            </div>
          )}
        </fieldset>
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size L</legend>
          {profilePhotos?.storeProfilePic ? (
            <Image
              className="h-[64px] w-[64px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.storeProfilePic?.path?.l}
              alt="storeProfilePic-size-L"
            />
          ) : (
            <div className="rounded-full bg-primary-100 p-2 h-[64px] w-[64px] ring-1 ring-primary-600 ring-opacity-10">
              <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="flex-shrink-0 h-full w-full text-primary-600">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V8.18322C19.2502 7.89772 19.1891 7.61553 19.071 7.35561L18.5332 6.17239C18.2086 5.45841 17.4967 5 16.7124 5H7.28807C6.50378 5 5.79188 5.45841 5.46734 6.1724L4.92951 7.35561C4.81137 7.61553 4.75024 7.89772 4.75024 8.18322V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.5 7.75C9.5 8.99264 8.5 10.25 7 10.25C5.5 10.25 4.75 8.99264 4.75 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 7.75C19.25 8.99264 18.5 10.25 17 10.25C15.5 10.25 14.5 8.99264 14.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M14.5 7.75C14.5 8.99264 13.5 10.25 12 10.25C10.5 10.25 9.5 8.99264 9.5 7.75"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
                ></path>
              </svg>
            </div>
          )}
        </fieldset>
      </div>
    </>
  );
};

export default VendorProfilePhotos;
