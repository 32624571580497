import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { showToast } from '../../../../store/slices/components.slice';
import { getPrivateFile } from '../../../../store/slices/file.slice';

import { classNames } from '../../../../utils';
import Image from '../../../../components/Image';

const Profile = ({ profile, id, hasUpdate }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userApi } = useSelector((state) => state);

  const handleGetPrivateFile = (file_path) => {
    dispatch(getPrivateFile(file_path))
      .unwrap()
      .then(async (result) => {
        const { signUrl } = result.data;
        const win = window.open(signUrl, '_blank');
        win.focus();
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const addresses = profile?.addresses;
  const addressDefault = addresses?.find((address) => address.isDefault);

  return (
    <>
      <dl className="divide-y">
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Name</dt>
          {userApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.name}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Email</dt>
          {userApi.loading ? <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" /> : <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.email}</dd>}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
          {userApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.phoneNumber}</dd>
          )}
        </div>

        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Stripe Customer ID</dt>
          {userApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile?.stripeCustomerId}</dd>
          )}
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Address</dt>
          {userApi.loading ? (
            <div className="animate-pulse h-4 w-auto bg-gray-400 rounded" />
          ) : (
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <table className="prose prose-sm">
                <tbody>
                  <tr>
                    <td className="font-medium">Address Name</td>
                    <td className={classNames(addressDefault?.name && 'font-mono')}>{addressDefault?.name || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Address Line 1</td>
                    <td>{addressDefault?.address1 || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Address Line 2</td>
                    <td>{addressDefault?.address2 || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Postal Code</td>
                    <td>{addressDefault?.postalCode || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">City</td>
                    <td>{addressDefault?.city || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">State</td>
                    <td>{addressDefault?.state || 'Not provided'}</td>
                  </tr>
                  <tr>
                    <td className="font-medium">Location</td>
                    <td>{`${addressDefault?.location?.lat}, ${addressDefault?.location?.lon}` || 'Not provided'}</td>
                    <td>
                      <a
                        href={`https://maps.google.com/?q=${addressDefault?.location?.lat},${addressDefault?.location?.lon}`}
                        target="_blank"
                        rel="noreferrer"
                        className="ml-auto font-sans font-medium text-primary-600 hover:text-primary-500"
                      >
                        <button type="button" className="ml-auto font-sans font-medium text-primary-600 hover:text-primary-500">
                          View map
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </dd>
          )}
        </div>
      </dl>
    </>
  );
};

export default Profile;
