import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Menu, Tab, Transition } from '@headlessui/react';

import Image from '../../../components/Image';

import { showToast } from '../../../store/slices/components.slice';
import { getUser } from '../../../store/slices/user.slice';

import Profile from './Profile';
import Orders from './Orders';
import { createImpersonateSession } from '../../../store/slices/admin.slice';

const UserDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { user_id } = useParams();
  const { userApi } = useSelector((state) => state);
  const [currentTab, setCurrentTab] = useState(0);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    switch (new URLSearchParams(location.search).get('view')) {
      case 'orders':
        setCurrentTab(1);
        break;
      default:
        setCurrentTab(0);
    }
    handleGetUser(user_id);
  }, []);

  const handleGetUser = (user_id) => {
    dispatch(getUser(user_id))
      .unwrap()
      .then((result) => {
        const { data } = result;
        setProfile(data);
      })
      .catch(({ message }) => {
        dispatch(showToast({ show: true, type: 'error', message: message }));
      });
  };

  const tabs = [
    {
      id: 0,
      key: 'profile',
      title: 'Profile',
      action: () => history.push(`${location.pathname}?view=profile`),
      panel: <Profile id={user_id} profile={profile} hasUpdate={() => handleGetUser(user_id)} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
    {
      id: 1,
      key: 'orders',
      title: 'Orders',
      action: () => history.push(`${location.pathname}?view=orders`),
      panel: <Orders id={user_id} profile={profile} hasUpdate={() => handleGetUser(user_id)} />,
      icon: (
        <svg className="w-5 h-5 mr-2 flex-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <>
      <ul class="flex items-center space-x-2">
        <li class="text-gray-500 text-sm font-medium">
          <Link to={'/user'}>Users</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        {userApi.loading ? (
          <div className="animate-pulse h-4 w-24 bg-gray-400 rounded flex-shrink-0"></div>
        ) : (
          <li class="text-gray-900 text-sm font-medium">{profile?.name || user_id}</li>
        )}
      </ul>
      <div class="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex items-center p-6 px-4 sm:px-6">
          {userApi.loading ? (
            <div className="animate-pulse bg-gray-400 rounded-full h-16 w-16 flex-shrink-0" />
          ) : (
            <Link
              className="relative overflow-hidden rounded-full ring-1 ring-black ring-opacity-5 flex-shrink-0"
              to={{
                pathname: `${location.pathname}/photos/profile`,
                state: profile,
              }}
            >
              <div className="absolute flex items-center justify-center h-16 w-16 hover:bg-black hover:bg-opacity-70 text-xs text-center hover:text-white text-transparent font-semibold">
                View all sizes
              </div>
              {profile?.profilePic ? (
                <Image
                  className="h-16 w-16 object-cover object-center ring-1 ring-black ring-opacity-10 flex-shrink-0"
                  src={profile?.profilePic?.path?.l}
                  alt="profilePic-size-L"
                />
              ) : (
                <div className="flex items-center h-16 w-16 rounded-full overflow-hidden bg-primary-100 focus:outline-none">
                  <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
              )}
            </Link>
          )}
          <div className="flex items-start w-full ml-4">
            <div>
              {userApi.loading ? (
                <div className="animate-pulse h-8 w-40 bg-gray-400 rounded" />
              ) : (
                <div className="flex items-center">
                  <h3 class="text-xl leading-6 font-bold text-gray-900">{profile?.name}</h3>
                  <p class="ml-3 max-w-2xl text-sm font-medium text-gray-500"></p>
                </div>
              )}
              {userApi.loading ? (
                <div className="mt-1 animate-pulse h-4 w-80 bg-gray-400 rounded"></div>
              ) : (
                <div>
                  <p className="mt-1 max-w-2xl text-xs text-gray-500">
                    ID: <span className="font-mono">{user_id}</span>
                  </p>
                  <p>
                    <button
                      onClick={() => {
                        dispatch(createImpersonateSession({ user: profile.email }))
                          .unwrap()
                          .then((result) => {
                            const { data } = result;
                            const navigateUrl = `${process.env.REACT_APP_NUBES_BASE_URL}callback-redirect-access?token=${data.token}`;
                            window.open(navigateUrl, '_blank').focus();
                          })
                          .catch(({ message }) => {
                            dispatch(showToast({ show: true, type: 'error', message: message }));
                          });
                      }}
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Impersonate User
                    </button>
                  </p>
                </div>
              )}
            </div>
            <div className="ml-auto">
              <Menu as="div" className="ml-auto relative inline-block text-left bg-white">
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                ></Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Tab.Group defaultIndex={currentTab}>
            <Tab.List className="flex space-x-4 border-b pb-4 px-4 sm:px-6">
              {tabs.map((tab, index) => (
                <Tab key={index} as={Fragment}>
                  {({ selected }) => (
                    <button
                      type="button"
                      onClick={tab.action}
                      className={
                        selected
                          ? 'flex items-center bg-primary-100 text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                          : 'flex items-center text-gray-500 hover:bg-primary-100 hover:text-primary-600 focus:outline-none px-3 py-2 rounded-md text-sm font-medium'
                      }
                    >
                      {tab.title}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab, index) => (
                <Tab.Panel className="focus:outline-none">{tab.panel}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
