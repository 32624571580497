import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// import Logo from '../components/Logo';
import { Transition } from '@headlessui/react';

import { toggleSidebar } from '../../store/slices/components.slice';
import authService from '../../services/auth.service';
import Image from '../Image';
import NubesLogo from '../Logo';

const Layout = {
  Header: () => {
    const [expandAvatar, setExpandAvatar] = useState(false);
    const [expandMessages, setExpandMessages] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const messagesNode = useRef();
    const avatarNode = useRef();

    const messages = [
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: '10 mins ago', subject: 'New screen created', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: '10 mins ago', subject: 'New screen created', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' },
      // { created: 'Just now', subject: 'Payment received', message: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It is more than that.' }
    ];

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleMessagesIconClick);
      document.addEventListener('mousedown', handleAvatarClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleMessagesIconClick);
        document.removeEventListener('mousedown', handleAvatarClick);
      };
    }, []);

    const handleMessagesIconClick = (e) => {
      if (messagesNode.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpandMessages(false);
    };

    const handleAvatarClick = (e) => {
      if (avatarNode.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setExpandAvatar(false);
    };

    return (
      <>
        <header className="flex-shrink-0">
          <div className="flex items-center h-16 border-b border-gray-200 shadow-md bg-white">
            <div className="px-6 sm:px-8 w-full">
              <div className="flex justify-between items-center py-3">
                <div className="flex flex-1 min-w-0">
                  <button type="button" onClick={() => dispatch(toggleSidebar(true))} className="text-gray-600 lg:hidden">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"></path>
                    </svg>
                  </button>
                </div>
                <div className="ml-6 flex-shrink-0 flex items-center">
                  <div className="flex items-center space-x-5 mr-5">
                    <div className="z-10 relative inline-block text-left" ref={messagesNode}>
                      <div>
                        <button type="button" onClick={() => setExpandMessages(!expandMessages)} className="relative flex items-center focus:outline-none">
                          <svg
                            className="w-7 h-7 text-gray-500 hover:text-gray-600 cursor-pointer"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            ></path>
                          </svg>
                          {messages.length > 0 && (
                            <span className="flex absolute h-2 w-2 top-2 right-2 -mt-1 -mr-1 ring-2 rounded ring-white">
                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                              <span className="relative inline-flex rounded-full h-2 w-2 bg-primary-500"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <Transition
                        show={expandMessages}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="origin-top-right right-0 absolute mx-[-3.8rem] sm:mx-0 mt-3 w-[24rem] sm:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="flex items-center justify-between text-sm px-4 py-2 border-b font-medium">
                            <span>Messages</span>
                            {messages.length > 0 && (
                              <button type="button" className="text-primary-600 hover:text-primary-700 font-medium">
                                Mark all as read
                              </button>
                            )}
                          </div>
                          <div>
                            <ul className="overflow-scroll h-[20rem] sm:h-[15rem] divide-y">
                              {messages.length > 0 ? (
                                messages.map((toast, index) => (
                                  <li key={index}>
                                    <div className="p-3 text-sm flex items-start hover:bg-primary-100 cursor-pointer">
                                      {/* <svg className="w-6 h-6 mr-2.5 flex-none text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> */}
                                      <div className="ml-1 mr-6">
                                        <span className="font-medium">{toast.subject}</span>
                                        <p className="mt-1 text-gray-500 leading-relaxed">{toast.message}</p>
                                        <p className="mt-2 text-xs text-primary-700">{toast.created}</p>
                                      </div>
                                      <span className="ml-auto cursor-pointer">
                                        <svg
                                          className="w-5 h-5 text-primary-600 hover:text-primary-700"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                    {index === messages.length - 1 && <div className="py-3 text-xs text-center text-gray-500">You're up to date 🎉</div>}
                                  </li>
                                ))
                              ) : (
                                <div className="flex flex-col items-center justify-center h-full text-gray-700">
                                  <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                                    ></path>
                                  </svg>
                                  <span className="mt-6 font-bold">You're all caught up!</span>
                                  <p className="mt-2 text-sm font-medium text-gray-500">There are no new messages unread</p>
                                </div>
                              )}
                            </ul>
                          </div>
                          <button
                            type="button"
                            className="w-full py-3 flex items-center justify-center font-medium text-sm bg-gray-50 text-primary-600 hover:bg-primary-100 border-t"
                          >
                            View all messages
                          </button>
                        </div>
                      </Transition>
                    </div>
                  </div>
                  <div className="flex w-full items-center space-x-3">
                    <div className="z-20 relative inline-block text-left" ref={avatarNode}>
                      <button type="button" onClick={() => setExpandAvatar(!expandAvatar)} className="flex items-center">
                        {/* <button type="button" onClick={() => setExpandAvatar(!expand)} className="flex items-center hover:text-primary-600">
                          <Image className="h-8 w-8 rounded-full object-cover" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=144&amp;q=80" alt="" />
                        </button> */}
                        <div className="flex items-center h-8 w-8 rounded-full overflow-hidden bg-primary-100 focus:outline-none">
                          <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </div>
                      </button>
                      <Transition
                        show={expandAvatar}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="absolute right-0 w-56 mt-2 origin-top-right text-gray-700 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          id="user-panel-menu-items"
                          role="menu"
                          tabIndex="0"
                        >
                          <div className="px-1 py-1" role="none">
                            <Link
                              to="/profile"
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="my-account"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <svg className="w-5 h-5 mr-2 group-hover:text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                              My profile
                            </Link>
                            <button
                              onClick={() => authService.logout(history)}
                              className="group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                              id="logout"
                              role="menuitem"
                              tabIndex="-1"
                            >
                              <svg className="w-5 h-5 mr-2 group-hover:text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                ></path>
                              </svg>
                              Log out
                            </button>
                          </div>
                          <div className="hidden px-1 py-1" role="none">
                            {/* 
                              Another section goes here
                              Note: Remove 'hidden' if you wish to use this section
                             */}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  },
  Sidebar: ({ path }) => {
    const [menuCollapse, setMenuCollapse] = useState();
    const sidebarToggled = useSelector((state) => state.components.sidebarToggled);
    const dispatch = useDispatch();

    const navigations = [
      { title: 'Home', path: '/' },
      { title: 'Vendors', path: '/vendors' },
      { title: 'Users', path: '/user' },
      // {
      //   title: 'Reports',
      //   path: '/store/reports',
      //   submenu: [
      //     { title: 'Sales', path: '/store/reports/sales' }
      //   ]
      // }
    ];

    const node = useRef();

    useEffect(() => {
      // add when mounted
      document.addEventListener('mousedown', handleDropdownClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleDropdownClick);
      };
    });

    const handleDropdownClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      dispatch(toggleSidebar(false));
    };

    const handleMenuCollapse = (path) => {
      if (menuCollapse === path) {
        setMenuCollapse(null);
      } else {
        setMenuCollapse(path);
      }
    };

    return (
      <>
        <div className={`fixed z-30 inset-0 bg-black bg-opacity-70 transition-opacity lg:hidden ${sidebarToggled ? 'block' : 'hidden'}`}></div>
        <nav
          ref={node}
          className={`fixed z-30 inset-y-0 left-0 w-64 px-8 py-3 bg-coolGray-50 border-r border-gray-300 overflow-auto transform transition lg:static lg:inset-auto lg:translate-x-0 ${
            sidebarToggled ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className="space-y-8">
            <div className="-mx-3 flex items-center justify-between h-14">
              <Link to="/" className="flex items-center">
                <NubesLogo className="flex-shrink-0 h-8 w-auto" />
                <span className="hidden sm:block ml-4 text-2xl font-logo text-gray-700 uppercase">Nubes Ops</span>
              </Link>
              <button type="button" onClick={() => dispatch(toggleSidebar(false))} className="text-gray-700 lg:hidden">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="mt-2 -mx-3">
              {navigations.map((nav, index) =>
                nav.submenu ? (
                  <>
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleMenuCollapse(nav.path)}
                      className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm w-full text-gray-700 hover:text-primary-600`}
                    >
                      <span className={`${nav.submenu ? 'font-semibold' : 'font-normal'}`}>{nav.title}</span>
                      {menuCollapse !== nav.path ? (
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                    </button>
                    <div className={menuCollapse === nav.path ? 'block' : 'hidden'}>
                      {nav.submenu.map((menu, key) => (
                        <Link
                          key={key}
                          to={menu.path}
                          className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm ${
                            `/${path.split('/')[1]}` === menu.path ? 'bg-primary-100 text-primary-600 font-semibold' : 'text-gray-500 hover:text-primary-600 hover:bg-primary-100'
                          }`}
                        >
                          <span>{menu.title}</span>
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    key={index}
                    to={nav.path}
                    className={`flex justify-between space-x-3 items-center px-3 py-2 my-1 rounded-lg text-sm ${
                      `/${path.split('/')[1]}` === nav.path ? 'bg-primary-100 text-primary-600 font-semibold' : 'text-gray-500 hover:text-primary-600 hover:bg-primary-100'
                    }`}
                  >
                    <span>{nav.title}</span>
                  </Link>
                )
              )}
            </div>
          </div>
        </nav>
      </>
    );
  },
  Content: ({ children }) => {
    return (
      <>
        <main className="flex-1 overflow-auto bg-gray-50 h-screen lg:h-auto text-gray-700 p-6 sm:p-8">{children}</main>
      </>
    );
  },
};

export default Layout;
