import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../services/user.service';

const initialState = {
  loading: false,
  payload: null,
  error: null,
};

export const listUser = createAsyncThunk('user/list_user', async (user_id, { rejectWithValue }) => {
  try {
    return await UserService.listUser(user_id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUser = createAsyncThunk('user/get_user', async (user_id, { rejectWithValue }) => {
  try {
    return await UserService.getUser(user_id);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    /** List users reducer **/
    [listUser.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [listUser.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [listUser.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
    /** Get user reducer **/
    [getUser.fulfilled]: (state, { meta, payload }) => {
      state.payload = payload.data;
      state.loading = false;
      state.error = null;
    },
    [getUser.pending]: (state, { meta }) => {
      state.loading = true;
    },
    [getUser.rejected]: (state, { meta, payload, error }) => {
      state.loading = false;
      state.payload = null;
      state.error = error;
    },
  },
});

export default reducer;
