import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Image from '../../../../components/Image';

const UserProfilePhotos = () => {
  const { user_id } = useParams();
  const location = useLocation();

  const [profilePhotos, setProfilePhotos] = useState(location.state);

  useState(() => {
    if (!location.state) {
    }
  }, []);

  return (
    <>
      <ul class="flex items-center space-x-2">
        <li class="text-gray-500 text-sm font-medium">
          <Link to={'/user'}>Users</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        <li class="text-gray-500 text-sm font-medium">
          <Link to={`/user/${user_id}?view=profile`}>{profilePhotos?.name || user_id}</Link>
        </li>
        <li>
          <svg class="h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </li>
        <li class="text-gray-900 text-sm font-medium">Profile photos</li>
      </ul>
      <div className="mt-6 space-y-8">
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size S</legend>
          {profilePhotos?.profilePic ? (
            <Image
              className="h-[32px] w-[32px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.profilePic?.path?.s}
              alt="profilePic-size-S"
            />
          ) : (
            <div className="flex items-center h-[32px] w-[32px] rounded-full overflow-hidden bg-primary-100 focus:outline-none">
              <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
          )}
        </fieldset>
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size M</legend>
          {profilePhotos?.profilePic ? (
            <Image
              className="h-[48px] w-[48px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.profilePic?.path?.m}
              alt="profilePic-size-M"
            />
          ) : (
            <div className="flex items-center h-[48px] w-[48px] rounded-full overflow-hidden bg-primary-100 focus:outline-none">
              <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
          )}
        </fieldset>
        <fieldset class="border border-solid border-gray-300 rounded-lg p-6">
          <legend class="px-2 font-semibold text-primary-600">Size L</legend>
          {profilePhotos?.profilePic ? (
            <Image
              className="h-[64px] w-[64px] rounded-full object-cover object-center ring-1 ring-black ring-opacity-10"
              src={profilePhotos?.profilePic?.path?.l}
              alt="profilePic-size-L"
            />
          ) : (
            <div className="h-[64px] w-[64px] flex items-center rounded-full overflow-hidden bg-primary-100 focus:outline-none">
              <svg className="h-full w-full text-primary-700" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
          )}
        </fieldset>
      </div>
    </>
  );
};

export default UserProfilePhotos;
